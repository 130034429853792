import React from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBCarousel,
    MDBCarouselItem,
    MDBCarouselInner,
    MDBMask,
    MDBRow,
    MDBView,
    MDBEdgeHeader,
    MDBFreeBird,
    MDBIcon
} from "mdbreact";
import './Homepage.css';
import wallpaper3 from '../assets/images/wallpaper12.jpg';
import magnify from '../assets/images/magnify.jpeg';
import search from '../assets/images/search.png';
import global from '../assets/images/gloabal.jpeg';
import ferrari from '../assets/images/ferrari.png';
import porsche from '../assets/images/porsche.png';
import redislabs from '../assets/images/redislabs.png';
import stanfordUniversity from '../assets/images/stanfordUniversity.png'
import olaCabs from '../assets/images/olaCabs.png';
import bharatPe from '../assets/images/bharatPe.png';
import meesho from '../assets/images/meesho.png';
import recko from '../assets/images/recko.png';
import indiaMart from '../assets/images/indiaMart.png';
import upgrad from '../assets/images/upgrad.png';
import carDekho from '../assets/images/carDekho.png';
import grofers from '../assets/images/grofers.png';
import licious from '../assets/images/licious.png';
import abfrl from '../assets/images/abfrl.png'
import delhivery from '../assets/images/delhivery.png';
import dominos from '../assets/images/dominos.png';
import inmobi from '../assets/images/inmobi.png';
import wpEngine from '../assets/images/wpEngine.png';
import switchMedia from '../assets/images/switchMedia.png';
import eruditus from '../assets/images/eruditus.png';
import lenskart from '../assets/images/lenskart.png';
import whitehatJr from '../assets/images/whitehatJr.jpeg';
import peachMode from '../assets/images/peachMode.png';
import kalki from '../assets/images/kalki.png';
import npiic from '../assets/images/npiic.png';
import wsa from '../assets/images/wsa.png';
import logo from '../assets/images/logo-large-white-2.png';
import logo1 from '../assets/images/logo-large.png';

export const HomePage: React.FC = (props: any) => {
    return (
        <MDBContainer fluid style={{ padding: "0px" }} >
            <MDBRow style={{ position: "relative", zIndex: "-1" , margin: "0px"}}>
                <MDBView>
                    <img
                        src={wallpaper3}
                        className="img"
                        alt=""
                        style={{ height: "97vh", width: "101vw", minHeight: "750px" }}
                    />
                    <MDBMask>
                        <MDBContainer>
                            <MDBRow style={{ margin: "5px" }}>
                                <MDBCol md="12" style={{ fontSize: "105px" }}>
                                    <MDBRow>
                                        <img
                                            src={logo}
                                            className="img"
                                            alt=""
                                            style={{ width: "50vw" }}
                                        />
                                        <br />
                                    </MDBRow>
                                    <MDBRow>
                                        <h1 style={{ fontSize: "3vw", fontWeight: 900, marginTop: "6vh", fontVariant: "all-small-caps" }} className="white-text">Protect your data with us.</h1>
                                        <br />
                                    </MDBRow>
                                    <MDBRow>
                                        <div className="css-typing" style={{ marginTop: "4vh" }}>

                                            -!- apnisec_ [apnisec_@10.3.0.172] has joined. Security as Service made easier #SECaaS.
                                            <p style={{ marginTop: "2vh" }} >
                                                <span style={{ color: "brown", fontSize: "1.5vw" }}>00:01 &lt;apni_sec&gt;</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}>:</span>
                                                <span style={{ color: "white", fontSize: "1.5vw" }}>~</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}># </span>
                                                hello from Apni Sec.
                                             </p>
                                            <p>
                                                <span style={{ color: "brown", fontSize: "1.5vw" }}>00:02 &lt;apni_sec&gt;</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}>:</span>
                                                <span style={{ color: "white", fontSize: "1.5vw" }}>~</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}># </span>
                                                We are here to make web a safer place.
                                             </p>
                                            <p>
                                                <span style={{ color: "brown", fontSize: "1.5vw" }}>00:03 &lt;apni_sec&gt;</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}>:</span>
                                                <span style={{ color: "white", fontSize: "1.5vw" }}>~</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}># </span>
                                                Keep an eye on your assets 24/7.
                                             </p>
                                            <p>
                                                <span style={{ color: "brown", fontSize: "1.5vw" }}>00:04 &lt;apni_sec&gt;</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}>:</span>
                                                <span style={{ color: "white", fontSize: "1.5vw" }}>~</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}># </span>
                                                Promote shift left culture with our solutions.
                                             </p>
                                            <p>
                                                <span style={{ color: "brown", fontSize: "1.5vw" }}>00:05 &lt;apni_sec&gt;</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}>:</span>
                                                <span style={{ color: "white", fontSize: "1.5vw" }}>~</span>
                                                <span style={{ color: "green", fontSize: "1.5vw" }}># </span>
                                                Join our journey.
                                             </p>
                                        </div>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBMask>
                </MDBView>
            </MDBRow>
            <div id="about">
                <MDBContainer>
                    <MDBRow className="pt-5 pb-3">
                        <MDBCard className="bg-transparent shadow-none">
                            <MDBCardBody className="p-0">
                                <div className=" w-100 ">
                                    <h1 className="text-center pt-3 font-weight-bolder" style={{ color: "#2bbbad" }}>
                                        About
                              </h1>
                                    <div className="ca-text-underline" style={{ background: "#2bbbad" }} />
                                </div>
                                <br />
                                <MDBCardTitle className="text-center black-text font-weight-bold p-0">Security as a Service made easier</MDBCardTitle>
                                <MDBCardText className="black-text" style={{ fontSize: "2px", visibility: "hidden" }}>
                                    Apni sec is started by passionate security researchers, white hat hackers and bug bounty hunters with an aim to protect organisations data from attackers by detecting, investigating and responding to cyber-threat.
                                    Our autonomous cyber defence platform have a 24/7 watch on potential attacks, all delivered as Security as a Service (SECaaS).Apni sec is started by passionate security researchers, white hat hackers and bug bounty hunters with an aim to protect organisations data from attackers by detecting, investigating and responding to cyber-threat.
                                    Our autonomous cyber defence platform have a 24/7 watch on potential attacks, all delivered as Security as a Service (SECaaS).Apni sec is started by passionate security researchers, white hat hackers and bug bounty hunters with an aim to protect organisations data from attackers by detecting, investigating and responding to cyber-threat.
                                    Our autonomous cyber defence platform have a 24/7 watch on potential attacks, all delivered as Security as a Service (SECaaS).Apni sec is started by passionate security researchers, white hat hackers and bug bounty hunters with an aim to protect organisations data from attackers by detecting, investigating and responding to cyber-threat.
                                    Our autonomous cyber defence platform have a 24/7 watch on potential attacks, all delivered as Security as a Service (SECaaS).Apni sec is started by passionate security researchers, white hat hackers and bug bounty hunters with an aim to protect organisations data from attackers by detecting, investigating and responding to cyber-threat.
                                    Our autonomous cyber defence platform have a 24/7 watch on potential attacks, all delivered as Security as a Service (SECaaS).
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBRow>
                </MDBContainer>
            </div>
            <div id="tools">
                <MDBContainer>
                    <MDBRow className="pt-5 pb-5">
                        <MDBCol md='4'>
                            <MDBCard>
                                <MDBCardBody style={{ padding: "0.5rem" }}>
                                    <MDBCardTitle>
                                        <div style={{ display: "inline-flex" }} className='justify-content-left'>
                                            <img src={magnify} style={{ height: "100px" }} className="img-fluid" alt="..." />
                                            <div className=" w-100 ">
                                                <h2 className="text-center pt-3 font-weight-bolder" style={{ color: "#2bbbad", marginLeft: "20px" }}>
                                                    Security
                                             </h2>
                                                <div className="ca-text-underline" style={{ background: "grey", opacity: 0.5, marginLeft: "20px", marginTop: "20px", width: "50px" }} />
                                            </div>
                                        </div>
                                    </MDBCardTitle>
                                    <MDBCardText>Security audits, server and client side vulnerabilities, penetration testing, data leaks,
                                    server side injections, broken auths and payments bypass.
                                </MDBCardText>
                                    <MDBBtn onClick={() => window.open('mailto:info@apnisec.com?subject=We would like to get in touch with you!&body=Query%20goes%20here')}  style={{ borderRadius: "5px", padding: "0.6rem 1.7rem" }} color="elegant">Get Demo</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='4'>
                            <MDBCard>
                                <MDBCardBody style={{ padding: "0.5rem" }}>
                                    <MDBCardTitle>
                                        <div style={{ display: "inline-flex" }} className='justify-content-left'>
                                            <img src={search} style={{ height: "100px" }} className="img-fluid" alt="..." />
                                            <div className=" w-100 ">
                                                <h2 className="text-center pt-3 font-weight-bolder" style={{ color: "#2bbbad", marginLeft: "20px" }}>
                                                    Watcher
                                             </h2>
                                                <div className="ca-text-underline" style={{ background: "grey", opacity: 0.5, marginLeft: "20px", marginTop: "20px", width: "50px" }} />
                                            </div>
                                        </div>
                                    </MDBCardTitle>
                                    <MDBCardText>Cyber defense platform that monitors organisation assets at scale, regularly checks for misconfigurations
                                    and security issues with pluggable alert system.
                                </MDBCardText>
                                    <MDBBtn onClick={() => window.open('mailto:info@apnisec.com?subject=We would like to get in touch with you!&body=Query%20goes%20here')} style={{ borderRadius: "5px", padding: "0.6rem 1.7rem" }} color="elegant">Get Demo</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='4'>
                            <MDBCard>
                                <MDBCardBody style={{ padding: "0.5rem" }}>
                                    <MDBCardTitle>
                                        <div style={{ display: "inline-flex" }} className='justify-content-left'>
                                            <img src={global} style={{ height: "100px" }} className="img-fluid" alt="..." />
                                            <div className=" w-100 ">
                                                <h2 className="text-center pt-3 font-weight-bolder" style={{ color: "#2bbbad", marginLeft: "20px" }}>
                                                    SCM Watcher
                                             </h2>
                                                <div className="ca-text-underline" style={{ background: "grey", opacity: 0.5, marginLeft: "20px", marginTop: "20px", width: "50px" }} />
                                            </div>
                                        </div>
                                    </MDBCardTitle>
                                    <MDBCardText>Monitors Github/Gitlab/Bitbucket for public sensitive details of an organisations. Passively reconnaissance and alert for sensitive details found publicly.</MDBCardText>
                                    <MDBBtn onClick={() => window.open('mailto:info@apnisec.com?subject=We would like to get in touch with you!&body=Query%20goes%20here')} style={{ borderRadius: "5px", padding: "0.6rem 1.7rem" }} color="elegant">Get Demo</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer>
                    <MDBRow className="pt-5">
                        <MDBCol md='4'>
                            <MDBCard>
                                <MDBCardBody style={{ padding: "0.5rem" }}>
                                    <MDBCardTitle>
                                        <div style={{ display: "inline-flex" }} className='justify-content-left'>
                                            <img src={magnify} style={{ height: "100px" }} className="img-fluid" alt="..." />
                                            <div className=" w-100 ">
                                                <h2 className="text-center pt-3 font-weight-bolder" style={{ color: "#2bbbad", marginLeft: "20px" }}>
                                                    Bizness
                                             </h2>
                                                <div className="ca-text-underline" style={{ background: "grey", opacity: 0.5, marginLeft: "20px", marginTop: "20px", width: "50px" }} />
                                            </div>
                                        </div>
                                    </MDBCardTitle>
                                    <MDBCardText>Feature releases focussed tool that discover vulnerabilities aligned to busniess logics.
                                    Facilitate Shift-Left culture and eliminate critical bugs.
                                </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='4'>
                            <span style={{ fontStyle: "italic" }}>Coming soon...</span>
                            <MDBCard>
                                <MDBCardBody style={{ padding: "1rem", opacity: 0.3 }}>
                                    <MDBCardTitle>
                                        <div style={{ display: "inline-flex" }} className='justify-content-left'>
                                            <img src={magnify} style={{ height: "100px" }} className="img-fluid" alt="..." />
                                            <div className=" w-100 ">
                                                <h2 className="text-center pt-3 font-weight-bolder" style={{ color: "#2bbbad", marginLeft: "20px" }}>
                                                    LogAI
                                             </h2>
                                                <div className="ca-text-underline" style={{ background: "grey", opacity: 0.5, marginLeft: "20px", marginTop: "20px", width: "50px" }} />
                                            </div>
                                        </div>
                                    </MDBCardTitle>
                                    <MDBCardText>Security in combination AI detects vulnearbilities</MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='4'>
                            <span style={{ fontStyle: "italic" }}>Coming soon...</span>
                            <MDBCard>
                                <MDBCardBody style={{ padding: "1rem", opacity: 0.3 }}>
                                    <MDBCardTitle>
                                        <div style={{ display: "inline-flex" }} className='justify-content-left'>
                                            <img src={magnify} style={{ height: "100px" }} className="img-fluid" alt="..." />
                                            <div className=" w-100 ">
                                                <h2 className="text-center pt-3 font-weight-bolder" style={{ color: "#2bbbad", marginLeft: "10px" }}>
                                                    Dark Watcher
                                             </h2>
                                                <div className="ca-text-underline" style={{ background: "grey", opacity: 0.5, marginLeft: "20px", marginTop: "20px", width: "50px" }} />
                                            </div>
                                        </div>
                                    </MDBCardTitle>
                                    <MDBCardText>Eyes and protects your data on the dark web.</MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
            <br />
            <MDBContainer fluid style={{ marginTop: "25px", padding: "0px" }}>
                <MDBEdgeHeader color="none" style={{ backgroundColor: "#ccc !important" }}>
                    <br />
                    <div className=" w-100 ">
                        <h2 className="text-center pt-3 font-weight-bolder" style={{ color: "white" }}>
                            We help making web a secure place
                                        </h2>
                        <div className="ca-text-underline" style={{ background: "white" }} />
                        <br />

                    </div>
                </MDBEdgeHeader>
                <MDBFreeBird id="free-bird" style={{ maxWidth: "2000px !important", width: "90vw", marginLeft: "5vw", backgroundColor: "#fcfcfc" }}>
                    <MDBRow>
                        <MDBCol md="12" lg="12" className="mx-auto float-none white z-depth-1">
                            <MDBCardBody style={{ padding: "0px" }}>
                                <br />
                                <MDBContainer fluid>
                                    <MDBCarousel activeItem={1} length={5} slide={true} showControls={false} showIndicators={true} multiItem>
                                        <MDBCarouselInner>
                                            <MDBRow>
                                                <MDBCarouselItem itemId="1">
                                                    <MDBRow>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ width: "100px", height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={ferrari} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ marginTop: "20px", height: "70px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={stanfordUniversity} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ width: "100px", height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={porsche} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "80px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={olaCabs} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ width: "100px", height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={redislabs} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ marginTop: "20px", height: "25px" }} className="img-fluid" src={bharatPe} />
                                                            </MDBCard>
                                                        </MDBCol>

                                                    </MDBRow>
                                                </MDBCarouselItem>
                                                <MDBCarouselItem itemId="2">
                                                    <MDBRow>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ width: "100px", height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={meesho} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ marginTop: "20px", height: "55px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={recko} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={carDekho} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "90px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={indiaMart} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "80px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={grofers} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ width: "100px", height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={upgrad} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCarouselItem>
                                                <MDBCarouselItem itemId="3">
                                                    <MDBRow>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={licious} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={delhivery} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={dominos} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ marginTop: "10px", height: "70px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={wpEngine} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "90px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={inmobi} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={abfrl} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCarouselItem>
                                                <MDBCarouselItem itemId="4">
                                                    <MDBRow>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={whitehatJr} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={switchMedia} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={npiic} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={lenskart} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={kalki} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ marginTop: "20px", height: "65px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={peachMode} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCarouselItem>
                                                <MDBCarouselItem itemId="5">
                                                    <MDBRow>
                                                        <MDBCol md='2'>
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ marginTop: "20px", height: "70px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={eruditus} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                        <MDBCol md="2">
                                                            <MDBCard className="mb-2" style={{ boxShadow: "none", backgroundColor: "#fafafa" }}>
                                                                <MDBCardImage style={{ height: "100px", marginRight: "auto", marginLeft: "auto" }} className="img-fluid" src={wsa} />
                                                            </MDBCard>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCarouselItem>
                                            </MDBRow>
                                        </MDBCarouselInner>
                                    </MDBCarousel>
                                </MDBContainer>
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBFreeBird>
            </MDBContainer>

            <MDBContainer fluid className="pb-5" >
                <br /><br />
                <br /><br />
                <MDBRow>
                    <MDBCol md="12">
                        <MDBCard className="bg-transparent shadow-none">
                            <MDBCardBody className="p-0">
                                <br />
                                <MDBCardTitle className="text-center font-weight-bold p-0" style={{ fontSize: "45px", color: "#2bbbad" }}>10000+ vulnerabilities</MDBCardTitle>
                                <br />
                                <MDBCardTitle className="text-center black-text font-weight-bold p-0">100+ organisations</MDBCardTitle>
                                <div className="ca-text-underline" style={{ background: "#2bbbad" }} />
                                <MDBCardText className="black-text" style={{ fontSize: "1rem", visibility: "hidden" }}>
                                    Apni sec is started by passionate security researchers, white hat hackers and bug bounty hunters with an aim to protect organisations data from attackers by detecting, investigating and responding to cyber-threat.
                                    Our autonomous cyber defence platform have a 24/7 watch on potential attacks, all delivered as Security as a Service (SECaaS).
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <br /><br />
            <MDBContainer fluid style={{ backgroundColor: "#fafafa", paddingBottom: "8rem" }}>
                <br /><br />
                <br /><br />
                <MDBRow style={{ marginRight: "0px", marginLeft: "0px" }}>
                    <MDBCol md="4">
                        <img
                            src={logo1}
                            className="img"
                            alt=""
                            style={{ width: "30%" }}
                        />
                        <br /><br />
                        <MDBCard className="bg-transparent shadow-none">
                            <MDBCardBody className="p-0">
                                <MDBCardText className="grey-text" style={{ fontSize: "1rem" }}>
                                    Apni sec is started by passionate security researchers, white hat hackers and bug bounty hunters with an aim to protect organisations data from attackers by detecting, investigating and responding to cyber-threat.
                                    Our autonomous cyber defence platform have a 24/7 watch on potential attacks, all delivered as Security as a Service (SECaaS).
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol md="4">
                        <div>
                            <p className="text-center font-weight-bold p-0" style={{ fontSize: "1rem", color: "black" }}>FOLLOW US</p>
                            <p className="text-center text-md-center text-white">
                                <MDBIcon fab onClick={() => window.open('https://www.facebook.com/apnisec.in')} icon="facebook" style={{ fontSize: "50px", color: "#4267B2", cursor: "pointer" }} />
                                <MDBIcon fab onClick={() => window.open('https://twitter.com/apnisec')} icon="twitter" style={{ fontSize: "50px", color: "#1DA1F2", cursor: "pointer" }} />
                                <MDBIcon fab onClick={() => window.open('https://www.linkedin.com/company/apnisec')} icon="linkedin" style={{ fontSize: "50px", color: "#0077b5", cursor: "pointer" }} />
                            </p>
                        </div>

                    </MDBCol>

                    <MDBCol md="4">
                        <MDBCard className="bg-transparent shadow-none">
                            <MDBCardBody className="p-0">
                                <MDBCardTitle className="text-center font-weight-bold p-0" style={{ fontSize: "1rem", color: "black" }}>CONTACT</MDBCardTitle>
                                <br />
                                {/* <MDBCardText className="text-center grey-text" style={{ fontSize: "1rem" }}>
                                    Phone: +91-8011034373
                                </MDBCardText> */}
                                <MDBCardText className="text-center grey-text" style={{ fontSize: "1rem" }}>
                                    Email: info@apnisec.com
                                </MDBCardText>
                                <MDBCardText className="text-center grey-text" style={{ fontSize: "1rem" }}>
                                    Address: Metropolis Office Park Plot No: 128-P2, EPIP ZONE, Adjacent to Ginger Hotel, Whitefield Industrial Area, Bangalore 560066
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBContainer>
    );
}