import React from "react";
import { MDBBtn, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBIcon, MDBCollapse, MDBNavbarToggler } from "mdbreact";

interface State {
    isOpen: any,
    user: boolean,
    username: any,
}
interface IProps {
    props?: any,
    UserChange?: any
}
export class Topheader extends React.Component<IProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            user: false,
            username: 'user',
        };
    }

    componentDidMount() {
        console.log(this.props)
    }
    componentDidUpdate(prevProps: any, newProps: any) {
        console.log(prevProps)
        console.log(this.props.props)
        if (this.props.props !== undefined && this.props !== false && this.props !== prevProps && this.props !== 'undefined' && this.props !== null) {
            this.setState({ user: true })
            console.log(this.props)
            this.setState({ username: this.props.props.username })
        }
    }
    getInTouch() {
        window.open('mailto:info@apnisec.com?subject=We would like to get in touch with you!&body=Query%20goes%20here')
    }
    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
      }

    render() {
        return (
            <MDBNavbar color="transparent" dark className="border-bottom-0" expand="md" style={{ top: "0", zIndex: "100", backgroundColor: "black", boxShadow: "none" }}>
                <div className="container">
                    <MDBNavbarBrand href="/">
                    </MDBNavbarBrand>

                    <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>

        {/* </MDBCollapse> */}
                    <MDBNavbarNav right>
                        <MDBNavItem style={{ paddingTop: "10px" }}>
                            <MDBNavLink className='text-light font-weight-bold' link to="/">HOME</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem style={{ paddingTop: "8px" }}>
                            <MDBNavLink className='text-light font-weight-bold' link to="/about.pdf" target="_blank" download>ABOUT <MDBIcon icon="file-download" style={{ fontSize: "20px", marginLeft: "3px" }} /></MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBBtn onClick={this.getInTouch} color="warning" style={{ color: "black", border: "2px solid #fb3", borderRadius: "20px" }}>Get in touch</MDBBtn>
                        </MDBNavItem>
                    </MDBNavbarNav>
                    </MDBCollapse>
                </div>
                
            </MDBNavbar>
        );
    }
}