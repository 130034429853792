import React from "react";
import { MDBBtn, MDBIcon } from "mdbreact";



export const Footer: React.FC = () => {
    return (
        <div className="page-footer font-small pt-3 pb-3" style={{backgroundColor: "#fafafa", border: "1px solid rgba(61,64,78,.12)"}}>
            <div className="container-fluid text-center text-md-left">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4 col-lg-8">
                        <p className="text-center text-md-left grey-text">
                            Copyright ©2021 Apni sec pvt. ltd.  &nbsp;&nbsp;  &nbsp;&nbsp; 
                        </p>
                    </div>
                    <div className="col-md-4 col-lg-4">
                        <p className="text-center text-md-right grey-text">
                        Disclaimer &nbsp;&nbsp;| &nbsp;&nbsp; Privacy Policy &nbsp;&nbsp; | &nbsp;&nbsp;Sitemap &nbsp;&nbsp;
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
