import './App.css';
import React from "react";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Footer} from "../common/Footer";
import {Topheader} from "../common/Topheader";
import {HomePage} from "../pages/HomePage";
const App: React.FC = () => {
    return (
        <Router>
            <Topheader />
            <switch>
                <Route exact path="/" component={HomePage}></Route>
            </switch>
            <Footer/>
        </Router>
    );
}
export default App;
